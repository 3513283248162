<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>配送员信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="配送员姓名:"
                                label-width="10em"
                            >
                                {{ shopDriver.driverName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="配送员手机号:"
                                label-width="10em"
                            >
                                {{ shopDriver.driverMobile }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="配送员类型:"
                                label-width="10em"
                            >
                                {{ shopDriver.driverTypeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="配送员状态:"
                                label-width="10em"
                            >
                                {{ shopDriver.driverStatusName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="总收益金额:"
                                label-width="10em"
                            >
                                {{ shopDriver.totalIncome }}元
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="已提现金额:"
                                label-width="10em"
                            >
                                {{ shopDriver.cashedAmount }}元
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="提现中金额:"
                                label-width="10em"
                            >
                                {{ shopDriver.cashingAmount }}元
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                    v-if="shopDriver.driverType === 2"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>身份证信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="身份证姓名:"
                                label-width="10em"
                            >
                                {{ shopDriver.idCardName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="身份证号:"
                                label-width="10em"
                            >
                                {{ shopDriver.idCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="身份证正面图:"
                                label-width="10em"
                            >
                                <ImageList
                                    :data="shopDriver.idCardFrontUrl"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="身份证背面图:"
                                label-width="10em"
                            >
                                <ImageList
                                    :data="shopDriver.idCardBackUrl"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <!--                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                    v-if="shopDriver.driverType === 2"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>银行卡信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="开户人姓名:"
                                label-width="10em"
                            >
                                {{ shopDriver.bankCardName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="开户账号:"
                                label-width="10em"
                            >
                                {{ shopDriver.bankCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="银行卡正面图:"
                                label-width="10em"
                            >
                                <ImageList
                                    :data="shopDriver.bankCardFrontUrl"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="银行卡背面图:"
                                label-width="10em"
                            >
                                <ImageList
                                    :data="shopDriver.bankCardBackUrl"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>-->
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>提现信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            label="提现类型:"
                            label-width="10em"
                        >
                            {{ shopDriverWithdraw.withdrawTypeName }}
                        </el-form-item>
                        <el-form-item
                            label="提现来源:"
                            label-width="10em"
                        >
                            {{ shopDriverWithdraw.withdrawSourceName }}
                        </el-form-item>
                        <el-form-item
                            label="提现金额:"
                            label-width="10em"
                        >
                            {{ shopDriverWithdraw.withdrawAmount }}
                        </el-form-item>
                        <el-form-item
                            label="兑换虚拟币金额:"
                            label-width="10em"
                        >
                            {{ shopDriverWithdraw.paymentAmount }}
                        </el-form-item>
                        <!--                        <el-form-item
                                                    label="提现手续费:"
                                                    label-width="10em"
                                                >
                                                    {{ shopDriverWithdraw.withdrawPoundage }}
                                                </el-form-item>
                                                <el-form-item
                                                    label="提现金额(扣除手续费):"
                                                    label-width="10em"
                                                >
                                                    {{ shopDriverWithdraw.withdrawAmount - shopDriverWithdraw.withdrawPoundage }}
                                                </el-form-item>-->
                        <!--                        <el-form-item
                                                    label="账户姓名:"
                                                    label-width="10em"
                                                    v-if="shopDriverWithdraw.withdrawType === 2"
                                                >
                                                    {{ shopDriverWithdraw.accountName }}
                                                </el-form-item>
                                                <el-form-item
                                                    label="账户账号:"
                                                    label-width="10em"
                                                    v-if="shopDriverWithdraw.withdrawType === 2"
                                                >
                                                    {{ shopDriverWithdraw.accountNum }}
                                                </el-form-item>-->
                        <el-form-item
                            label="提现时间:"
                            label-width="10em"
                        >
                            {{ shopDriverWithdraw.withdrawTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                        <el-form-item
                            label="提现状态:"
                            label-width="10em"
                        >
                            {{ shopDriverWithdraw.withdrawStatusName }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="shopDriverWithdraw.withdrawStatus !== 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>审核信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            label="审核操作人:"
                            label-width="10em"
                        >
                            {{ shopDriverWithdraw.auditName }}
                        </el-form-item>
                        <el-form-item
                            label="审核时间:"
                            label-width="10em"
                        >
                            {{ shopDriverWithdraw.auditTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                        <el-form-item
                            label="付款凭证:"
                            label-width="10em"
                            v-if="shopDriverWithdraw.withdrawType === 1"
                        >
                            <ImageList
                                :data="shopDriverWithdraw.paymentVoucherList"
                            />
                        </el-form-item>
                        <el-form-item
                            label="备注:"
                            label-width="10em"
                        >
                            {{ shopDriverWithdraw.remarks }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="shopDriverWithdraw.withdrawStatus === 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>提现审核</span>
                    </div>
                    <div class="item">
                        <el-form
                            ref="addForm"
                            size="small"
                            :model="formModel"
                            :rules="formRules"
                        >
                            <el-form-item
                                prop="auditStatus"
                                label="审核是否通过:"
                                label-width="10em"
                            >
                                <el-select
                                    v-model="formModel.auditStatus"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        :key="99"
                                        label="审核不通过"
                                        :value="99"
                                    />
                                    <el-option
                                        :key="100"
                                        label="审核通过"
                                        :value="100"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                prop="auditRemarks"
                                label="不通过原因:"
                                label-width="10em"
                                v-if="formModel.auditStatus === 99"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="formModel.auditRemarks"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="paymentAmount"
                                label="到账虚拟币金额"
                                label-width="10em"
                                v-if="formModel.auditStatus === 100"
                            >
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="formModel.paymentAmount"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="paymentVoucher"
                                label="付款凭证"
                                label-width="10em"
                                v-if="formModel.auditStatus === 100 && shopDriverWithdraw.withdrawType === 1"
                            >
                                <upload
                                    validate-md5
                                    action="/rs/attachment/uploadFileLibrary"
                                    :limit="5"
                                    v-model="formModel.paymentVoucher"
                                />
                            </el-form-item>
                        </el-form>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    class="ma-t"
                >
                    <el-form-item
                        label-width="13em"
                    >
                        <el-button
                            v-if="shopDriverWithdraw.withdrawStatus === 1"
                            type="primary"
                            size="small"
                            @click="onConfirm()"
                        >
                            提交
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-card>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopDriverWithdrawDetail',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                paymentAmount: '',
                paymentVoucher: [],
                auditStatus: '',
                auditRemarks: '',
            },
            // 表单校验规则
            formRules: {
                paymentAmount: {
                    required: true,
                    message: '请输入到账虚拟币金额',
                    trigger: 'blur',
                },
                auditStatus: {
                    required: true,
                    message: '请选择审核状态',
                    trigger: 'blur',
                },
                auditRemarks: {
                    required: true,
                    message: '请输入审核备注',
                    trigger: 'blur',
                },
                paymentVoucher: {
                    type: 'array',
                    required: true,
                    message: '付款凭证不能为空',
                },
            },
            shopDriverWithdraw: {
                paymentVoucherList: [],
            },
            shopDriver: {},
        };
    },
    methods: {
        init() {
            this.$api.Sp.ShopDriverWithdraw.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.shopDriverWithdraw = res.shopDriverWithdraw || {};
                this.formModel.paymentAmount = this.shopDriverWithdraw.paymentAmount;
                this.shopDriver = res.shopDriver || {};
            });
        },
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否确定提交审核结果！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Sp.ShopDriverWithdraw.audit({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.$router.back();
                    });
                });
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
