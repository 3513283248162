<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>买家信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            label="买家手机:"
                            label-width="8em"
                        >
                            {{ result.mobile }}
                        </el-form-item>
                        <el-form-item
                            label="注册时间:"
                            label-width="8em"
                        >
                            {{ result.registerTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>提现信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            label="提现申请单号:"
                            label-width="8em"
                        >
                            {{ result.withdrawApplySn }}
                        </el-form-item>
                        <el-form-item
                            label="提现金额:"
                            label-width="8em"
                        >
                            {{ result.withdrawAmount }}
                        </el-form-item>
                        <el-form-item
                            label="提现手续费:"
                            label-width="8em"
                        >
                            {{ result.withdrawPoundage }}
                        </el-form-item>
                        <el-form-item
                            label="实际提现金额:"
                            label-width="8em"
                        >
                            {{ result.paymentAmount }}
                        </el-form-item>
                        <el-form-item
                            label="申请时间:"
                            label-width="8em"
                        >
                            {{ result.withdrawTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                        <el-form-item
                            label="提现方式:"
                            label-width="8em"
                        >
                            {{ result.withdrawTypeName }}
                        </el-form-item>
                        <el-form-item
                            label="微信姓名:"
                            label-width="8em"
                            v-if="result.withdrawType === 2"
                        >
                            {{ result.accountName }}
                        </el-form-item>
                        <el-form-item
                            label="微信收款码:"
                            label-width="8em"
                            v-if="result.withdrawType === 2"
                        >
                            <ImageList
                                :data="result.accountNumUrl"
                            />
                        </el-form-item>
                        <el-form-item
                            label="支付宝姓名:"
                            label-width="8em"
                            v-if="result.withdrawType === 4"
                        >
                            {{ result.accountName }}
                        </el-form-item>
                        <el-form-item
                            label="支付宝手机号:"
                            label-width="8em"
                            v-if="result.withdrawType === 4"
                        >
                            {{ result.accountNum }}
                        </el-form-item>
                        <el-form-item
                            label="提现状态:"
                            label-width="8em"
                        >
                            {{ result.withdrawStatusName }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="result.withdrawStatus !== 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>审核信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            label="审核操作人:"
                            label-width="8em"
                        >
                            {{ result.auditName }}
                        </el-form-item>
                        <el-form-item
                            label="审核时间:"
                            label-width="8em"
                        >
                            {{ result.auditTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                        <el-form-item
                            label="审核说明:"
                            label-width="8em"
                        >
                            {{ result.auditRemarks }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="result.withdrawStatus === 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>审核信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            prop="auditStatus"
                            label="审核是否通过:"
                            label-width="10em"
                        >
                            <el-select
                                v-model="formModel.auditStatus"
                                placeholder="请选择"
                            >
                                <el-option
                                    :key="99"
                                    label="审核不通过"
                                    :value="99"
                                />
                                <el-option
                                    :key="100"
                                    label="审核通过"
                                    :value="100"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            prop="auditRemarks"
                            label="不通过原因:"
                            label-width="10em"
                            v-if="formModel.auditStatus === 99"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.auditRemarks"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="paymentVoucher"
                            label="付款凭证"
                            label-width="10em"
                            v-if="formModel.auditStatus === 100 && (result.withdrawType === 2 || result.withdrawType === 4)"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="5"
                                v-model="formModel.paymentVoucher"
                            />
                        </el-form-item>
                    </div>
                </el-card>
                <el-col :span="12">
                    <el-form-item
                        label-width="8em"
                        class="ma-t"
                    >
                        <el-button
                            v-if="result.withdrawStatus === 1"
                            type="primary"
                            size="small"
                            @click="onConfirm()"
                        >
                            提交
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ResultOfflineAuditDetail',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                auditStatus: '',
                auditRemarks: '',
                paymentVoucher: [],
            },
            // 表单校验规则
            formRules: {
                paymentAmount: {
                    required: true,
                    message: '请输入到账虚拟币金额',
                    trigger: 'blur',
                },
                auditStatus: {
                    required: true,
                    message: '请选择审核状态',
                    trigger: 'blur',
                },
                auditRemarks: {
                    required: true,
                    message: '请输入审核备注',
                    trigger: 'blur',
                },
                paymentVoucher: {
                    type: 'array',
                    required: true,
                    message: '付款凭证不能为空',
                },
            },
            result: {},
        };
    },
    methods: {
        init() {
            this.$api.Mb.BuyerWithdraw.getDetail({ id: this.formModel.id, source: this.formModel.source }).then(
                json => {
                    const res = json.data.data;
                    this.result = res;
                },
            );
        },
        onConfirmPass() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否确定审核通过！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.formModel.auditStatus = 100;
                    this.$api.Mb.BuyerWithdraw.audit({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.init();
                    });
                });
            });
        },
        onConfirmErr() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否确定审核不通过！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.formModel.auditStatus = 99;
                    this.$api.Mb.BuyerWithdraw.audit({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.init();
                    });
                });
            });
        },
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否确定提交审核结果！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Mb.BuyerWithdraw.audit({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.$router.back();
                    });
                });
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
