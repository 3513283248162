<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.withdrawStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="待审核"
                    name="1"
                />
                <el-tab-pane
                    label="审核通过"
                    name="100"
                />
                <el-tab-pane
                    label="审核不通过"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="withdrawApplySn"
                    label="提现申请单号"
                >
                    <el-input
                        v-model="queryFormModel.withdrawApplySn"
                        placeholder="请输入提现申请单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverName"
                    label="配送员姓名"
                >
                    <el-input
                        v-model="queryFormModel.driverName"
                        placeholder="请输入配送员姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverMobile"
                    label="配送员电话"
                >
                    <el-input
                        v-model="queryFormModel.driverMobile"
                        placeholder="请输入配送员电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="买家手机号"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入买家手机号"
                    />
                </el-form-item>
                <el-form-item
                    prop="withdrawType"
                    label="提现方式"
                >
                    <dictionaries-picker
                        type="buyerWithdrawType"
                        v-model="queryFormModel.withdrawType"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="withdrawApplySn"
                    label="提现申请单号"
                    min-width="100"
                />
                <el-table-column
                    prop="driverName"
                    label="配送员姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="driverMobile"
                    label="配送员电话"
                    min-width="100"
                />
                <el-table-column
                    prop="withdrawTypeName"
                    label="提现方式"
                    min-width="100"
                />
                <el-table-column
                    prop="withdrawAmount"
                    label="提现金额"
                    min-width="100"
                />
                <el-table-column
                    label="申请时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.withdrawTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/sp/shopDriver/shopDriverWithdrawAudit/Detail/' + scope.row.id)"
                        >
                            {{ scope.row.withdrawStatus == 1 ? '审核' : '查看' }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'BuyerWithdrawAudit',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                driverName: '',
                driverMobile: '',
                withdrawType: '',
                withdrawStatus: '1',
                withdrawApplySn: '',
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sp.ShopDriverWithdraw.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
